$(function() {
    $(".mobile-button").click(function(e) {
        e.preventDefault();
        $(".mobile-nav").toggleClass("open");
    });


    var options = {
        event: 'click', // * View note below
        selector: 'a', // * View note below
        speed: 400,

    };

    $('.drilldown').drilldown(options);
});
